(function($){

    var $doc = $(document);

    c7basic = {
        init: function()
        {
            /**
             * Captura o click de botões que abrem o modal, carregando o conteúdo
             * do servidor.
             */
            $doc.on('click', '[data-modal]', function(event) {
                var $modal = $( $(this).attr('data-modal') );
                var options = $(this).attr('data-options');
                var url = ($(this).attr('data-url') ? $(this).attr('data-url') : $modal.attr('data-url'));

                $.ajax({
                    url: url,
                    method: 'get',
                    data: options,
                    success: function(resp) {
                        $modal.html(resp).modal('show');

                        // $modal.find('.modal-body').click(function(e){
                        //     e.stopPropagation();
                        // });

                        var modal_exec = $modal.attr('data-modal-exec');
                        if (modal_exec) {
                            switch(modal_exec) {
                                case 'cs-exec':
                                    c7cs.modal_exec();
                                break;
                            }
                        }

                        $modal.find('[data-oninit]').each(function() {
                            var func = $(this).data('oninit');
                            window[func](this);
                        });
                    }
                });
            });

            /**
             * Modais que possuem conteúdo dinâmico devem ter seu html destruído
             * quando são fechadas.
             */
            $doc.on('hide.bs.modal', 'modal[data-url]', function() {
                $(this).html('');
            });

            /**
             * Captura o click de botões que irão duplicar um conteúdo em especĩfico
             */

            $doc.on('click', '[data-dyn-field]', function(event) {

                var dyn_field_obj = $(this).attr('data-dyn-field');
                var apply_custom = $(this).attr('data-apply-custom');

                var dyn_field_set_obj = $('#' + dyn_field_obj).find('.m-dyn-fields-set:visible');
                var dyn_field_set_clone = dyn_field_set_obj.first().clone();

                var dyn_field_i = dyn_field_set_obj.length + 1;

                dyn_field_set_clone.find('.counter').text( dyn_field_i );
                dyn_field_set_clone.find('input[value]').removeAttr('value');
                dyn_field_set_clone.find('textarea').html('');
                dyn_field_set_clone.find('[selected]').removeAttr('selected');

                $('#' + dyn_field_obj).append( dyn_field_set_clone.wrap('<div/>').parent().html().replace(new RegExp("\\[0\\]", "gm"), "[" + (dyn_field_i - 1) + "]").replace(new RegExp("\\.0\\.", "gm"), "." + (dyn_field_i - 1) + ".") );

                var dyn_field_last = $('#' + dyn_field_obj).find(':last-child');
                if( dyn_field_last.find('[data-inputmask]').length ) c7basic.apply_mask(dyn_field_last.find('[data-inputmask]'));
                if( dyn_field_last.find('.m-timepicker').length ) c7basic.apply_timepicker(dyn_field_last.find('.m-timepicker'));
                if( dyn_field_last.find('.m-datepicker').length ) c7basic.apply_datepicker(dyn_field_last.find('.m-datepicker'));

                c7basic.apply_validate();

                if ( apply_custom ) {
                    c7basic.apply_custom(apply_custom, dyn_field_last);
                }

            });

            $doc.on('click','.act-dyn-field-remove', function(){

                var this_set = $(this).closest('.m-dyn-fields-set');
                this_set.find('.act-dyn-field-clean').val('').html('');
                if( this_set.siblings(':visible').length > 0 && this_set.index() > 0 ) this_set.slideUp('slow', function(){
                    this_set.parent().find('.m-dyn-fields-set:visible').each(function(i){
                        $(this).find('.counter').text( i + 1);
                    });
                });

            });

            $inputmask = $("[data-inputmask]");
            if($inputmask.length) $inputmask.each(function(){
                c7basic.apply_mask($(this));
            });

            c7basic.do_confirm();

            if($('.act-do-export').length) c7basic.add_export_option();

        },

        apply_datepicker: function(el)
        {
            el.datepicker({
                language: "pt-BR",
                calendarWeeks: true,
                todayHighlight: true,
                zIndexOffset: 1100,
                format: 'yyyy-mm-dd'
            });
        },

        apply_timepicker: function(el)
        {
            el.timepicker({
                minuteStep: 1,
                showInputs: false,
                showSeconds: false,
                showMeridian: false
            });
        },

        apply_mask: function(el, mask_type = null) {
            if (!mask_type) {
                mask_type = {};
            }
            $(el).inputmask(mask_type);
        },

        remove_mask: function(el) {
            $(el).inputmask('remove');
        },

        apply_validate: function(el) {
            $(el).on('submit', function(){

                var csrf_token = $('input[name="_token"]').val();
                var el_fields = $(el).serialize();
                var form_submit = $(el).hasClass('m-submit');

                $(el).find('.errors-container').remove();
                $(el).find('.has-errors').removeClass('has-errors');

                if (!form_submit) {

                    $.ajax({
                        method: 'POST',
                        url: $(el).attr('action'),
                        headers: {
                            'X-CSRF-TOKEN': csrf_token
                        },
                        data: el_fields
                    }).fail(function (jqXHR, status){

                        var error_obj = $.parseJSON( jqXHR.responseText );
                        $.each(error_obj, function(key, value){
                            key = key.replace(/\./g, '\\.');
                            console.log( key );
                            console.log( $('#' + key).length );
                            $('#' + key).parent().addClass('has-errors').prepend('<div class="errors-container"></div>');
                            var errors_container = $('#' + key).parent().find('.errors-container');
                            $.each(value, function(val_index, val_value){
                                errors_container.append('<b>'+ val_value +'</b>');
                            });
                        });

                        $(el).find('.errors-container').on('click', function(e){
                            $(this).remove();
                        });

                    }).done(function(data){
                        $(el).addClass('m-submit');
                        $(el).submit();
                    });

                }

                return form_submit;
            });
        },

        // para aplicar funções específicas
        apply_custom: function(code, el = null){
            switch(code){
                case 'cs-contact-dyn-field':
                    c7cs.contact_dyn_field(el);
                break;
                case 'cs-action-dyn-field':
                    c7cs.action_dyn_field(el);
                break;
            }
        },

        // para aplicar a opção de exportar a lista
        add_export_option: function(){
            $('.act-do-export').each(function(){
                $(this).click(function(e){
                    e.preventDefault();
                    var form_el = $(this).closest('form');
                    if(!form_el.attr('data-action')) form_el.attr('data-action', form_el.attr('action'));

                    form_el.attr('action', form_el.attr('data-export'));
                    form_el.submit();

                    setTimeout(function(){
                        form_el.attr('action', form_el.attr('data-action'));
                    }, 1000);
                });
            });
        },

        do_confirm: function(){

            $doc.on('click', '.act-do-confirm', function(e){
                e.preventDefault();

                var this_el = $(this);
                var swal_title = 'Você tem certeza?';
                var swal_text = 'Esta ação não poderá ser desfeita!';

                if (this_el.attr('data-confirm-title')) swal_title = this_el.attr('data-confirm-title');
                if (this_el.attr('data-confirm-text')) swal_text = this_el.attr('data-confirm-text');

                // se for link
                var this_el_href = this_el.attr('href');
                if (this_el_href && this_el_href != 'javascript:;' && this_el_href != '#') var data_link = this_el.attr('href');

                // se for chamar uma função
                if (this_el.attr('data-function')) var data_function = this_el.attr('data-function');

                // se um botão de submit
                if (this_el.attr('type') == 'submit') var data_submit = true;

                swal({
                    title: swal_title,
                    text: swal_text,
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Prosseguir',
                    cancelButtonText: 'Cancelar',
                }).then(function() {
                    if (data_link)  {
                        window.location.href = data_link;
                        return;
                    }
                    if (data_function) {
                        var parts = data_function.split(".");
                        for (var i = 0, len = parts.length, obj = window; i < len; ++i) {
                            obj = obj[parts[i]];
                        }
                        return obj(this_el);
                    }
                    if (data_submit) {
                        this_el.closest('form').submit();
                    }
                }, function(dismiss) {
                    return false;
                });

            });
        }

    };

})(jQuery);

c7basic.init();